.ordersPopupType {
  display: flex;
  column-gap: 8px;
  margin-bottom: 15px;

  &__label {
    font-size: 20px;
    opacity: 0.3;
    margin-bottom: 10px;
  }

  &__item {
    width: 50%;
    height: 39px;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    p {
      position: absolute !important;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 14px;
      pointer-events: none;
    }
  }
}

.ordersPopupType__item:has(input:checked) p {
  color: #fff;
}