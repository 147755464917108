.historyOrdersActionsCVV {
  display: flex;
  //width: calc(100% - 32px);
  height: 65px;
  column-gap: 8px;
  justify-content: space-between;
  align-items: center;
  //position: fixed;
  //bottom: 50px;
  margin-top: 12px;

  button {
    border-radius: 18px;
    height: 100%;
    width: 100%;
    max-height: 64px;

    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.done span {
  color: #fff;
}
