.managerList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: auto;
}

.item {
  border-radius: 18px;
  background-color: #0D182E;
  display: flex;
  justify-content: space-between;
  padding: 18px 16px;

  &__name {
    display: flex;
    align-items: center;
    column-gap: 12px;

    img {
      width: 32px;
      height: 32px;
    }

    p {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__value {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-end;

    p {
      font-size: 14px;
      font-weight: 600;
    }

    span {
      font-size: 14px;
    }
  }
}