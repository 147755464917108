.accountList {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-height: calc(100vh - 240px);
  overflow: auto;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    background-color: #0D182E;
    border-radius: 18px;

    p {
      font-size: 18px;
      font-weight: 700;
    }
  }
}