.historyTransactions {
  height: calc(100% - 122px);
}

.filter {
  height: 47px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-radius: 18px;
  padding: 0 16px;
  margin-bottom: 34px;

  span {
    font-size: 18px;
  }

  img {
    right: 16px;
    width: 24px;
    height: 24px;pointer-events: none;
  }
}