.walletSettings, .walletSettingsActive {
  position: fixed;
  left: 50%;
  bottom: -2000px;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  transition: all .2s;
  z-index: 3;
  padding: 16px 16px 32px;
  border-radius: 18px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 24px auto 32px;
    text-align: center;
  }
}

.walletSettingsActive {
  bottom: 0;
}

.boxWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.box {
  border-radius: 18px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;

  &__addressInfo {

  }

  &__label {
    opacity: 0.5;
    font-size: 15px;
  }

  &__value {
    font-size: 20px;
  }

  input {
    padding-left: 0;
  }

  &__actions button {
    height: 65px;
    width: 100%;
    margin-top: 24px;
    border-radius: 18px;

    &:disabled {
      opacity: 0.3;
    }

    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.addressWrapper {
  display: flex;
  column-gap: 8px;

  &__action {
    width: 87px;
    min-width: 87px;
    height: 87px;
    border-radius: 18px;
  }
}