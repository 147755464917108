.orderItemHistory, .orderItemHistoryActive {
  position: absolute;
  left: -150%;
  top: 0;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  height: 100vh;
  background-position: 50% 5%;
  background-size: 115% 110%;
  background-repeat: no-repeat;
  overflow: auto;
  transition: all .1s;
  z-index: 1;

  &__content {
    height: calc(100vh - 252px);
    margin-top: 45px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
}

.orderItemHistoryActive {
  left: 50%;
}

.value {
  border-radius: 18px;
  padding: 18px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;

  p {
    font-size: 40px;
  }

  span {
    font-size: 23px;
    opacity: 0.3;
  }
}

.status {
  border-radius: 18px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 8px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 15px;
    }

    span {
      color: #E7DE00;
    }
  }

  &__itemData {
    display: flex;
    align-items: center;
    column-gap: 8px;

    span {
      color: #fff;
    }
  }
}

.processingId {
  padding: 13px 20px;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  &__address {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    span {
      font-size: 12px;
      opacity: 0.5;
    }

    p {
      font-size: 18px;
    }
  }

  button {
    width: 44px;
    height: 39px;
    border-radius: 8px;
  }
}

.action {
  height: 62px;
  width: 100%;
  border-radius: 18px;
  min-height: 62px;

  margin-top: auto;

  span {
    font-size: 18px;
    font-weight: 600;
  }
}