.walletItem {
  height: 100vh;
  padding: 23px 16px 102px;
  overflow: auto;
}

.closer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  z-index: 1;
}