.filterPopup, .filterPopupActive {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  z-index: 15;
  max-width: 640px;
  transform: translateX(-50%);
  padding: 45px 16px 102px;
  transition: all 0.2s;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 29px;

    h2 {
      font-size: 24px;
      font-weight: 700;
    }

    span {
      pointer-events: none;
      opacity: 0;
    }
  }

  &__search {
    position: relative;

    img {
      position: absolute;
      left: 14px;
      top: calc(50% - 4px);
      transform: translateY(-50%);
    }

    input{
      height: 40px;
      width: 100%;
      margin-bottom: 8px;
    }
  }
}

.filterPopup {
  top: -2000px;
}

.empty {
  margin-top: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 24px;

  img {
    opacity: 0.5;
  }

  p {
    text-align: center;
    max-width: 134px;
    opacity: 0.5;
    font-size: 23px;
    line-height: 25.3px;
  }
}