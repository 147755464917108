.language {
  width: 100%;
  height: 100vh;
  max-width: 640px;
  padding: 23px 16px 102px;
  overflow: auto;

  ul {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  li {
    padding: 14px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
}