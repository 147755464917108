.exchange {
  display: flex;
  column-gap: 8px;
}

.box {
  padding: 5px 16px;
  border-radius: 18px;
  width: 50%;
  min-width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  max-height: 48px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    opacity: 0.5;
  }

  &__value {
    display: flex;
    column-gap: 14px;
    font-size: 20px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      padding-left: 0;
      font-size: 20px;
      width: 90%;
      overflow: hidden;
    }

    p {
      width: 90%;
      overflow: hidden;
    }
  }
}