.transactionItem {
  padding: 18px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__operation {
    display: flex;
    column-gap: 16px;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: 18px;
    }
  }

  &__value {
    display: flex;
    align-items: center;
    column-gap: 4px;

    span {
      font-size: 18px;
    }
  }

  &__date {
    display: flex;
    column-gap: 4px;

    span {
      font-size: 12px;
      opacity: 0.5;
    }
  }
}