.copyCode, .copyCodeLight {
  display: flex;
  border-radius: 18px;
  padding: 8px 14px;
  align-items: center;
  justify-content: space-between;


  button {
    border-radius: 8px;
    width: 52px;
    height: 48px;
    background-color: #132240;
  }
}

.copyCodeLight {
  button {
    background-color: #82A5E2;
  }
}