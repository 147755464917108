.walletSearch {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &__search {
    position: relative;

    img {
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__searchInput {
    width: 147px;
    height: 52px;
  }

  &__hide {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 10px;
    max-width: 143px;
    width: 143px;
    column-gap: 8px;

    span {
      margin-right: 8px;
      display: block;
    }

    p {
      position: absolute;
      padding-top: 1px;
      font-size: 15px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
      max-width: 112px;
    }
  }
}

.hideInput {
  width: 100% !important;
  height: 16px !important;
  opacity: 0;
  padding: 0 !important;
  margin-right: 7px;
}

.circle {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}