.wrapper, .wrapperLight {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 95px;
  max-width: 640px;
  background-color: #0A0C24;


  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    background-color: #0A0C24;
    width: 100%;
    height: 100%;
    z-index: 4;
  }
}

.historyCVVBtn {
  bottom: 25px;
  z-index: 5;
  left: 16px;
}

.wrapperLight {
  &:before {
    background-color: #EDF3FF;
  }
}