.walletFilter {
  display: flex;
  column-gap: 4px;
  margin-bottom: 16px;

  &__checkbox {
    height: 40px;
    border-radius: 14px;
    width: 100%;
    position: relative;

    input {
      height: 100%;
      width: 100%;
      opacity: 0;
    }
  }

  &__label {
    pointer-events: none;
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    border-radius: 14px;
  }
}

.text {
  padding-top: 1px;
  font-size: 15px;
}