.navigationWrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 96px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  width: 100%;
  max-width: 640px;
  display: flex;
  padding: 18px 15px 19px;
  justify-content: space-between;
  z-index: 2;
}

.navigationItem, .navigationItemActive  {
  padding: 8px 0;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 6px;
  width: 64px;
  transition: all .15s;

  &__icon {
    display: flex;
    justify-content: center;
  }

  img {
    width: 24px;
    height: 24px;
  }

  p {
    font-size: 15px;
    text-align: center;
  }
}

.navigationItemActive {
  border-radius: 18px;
}

.hide {
  display: none;
}

p.activeText {
  color: #fff;
}