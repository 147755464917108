.previewWithdraw, .previewWithdrawActive {
  position: fixed;
  left: 50%;
  bottom: -1000px;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  max-height: calc(100vh - 85px);
  transition: all .2s;
  z-index: 3;
  padding: 16px 16px 32px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  overflow: auto;
}

.previewWithdrawActive {
  bottom: 0;
}

.value {
  border-radius: 18px;
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 32px;

  &__token {
    font-size: 40px;
  }

  &__dollars {
    font-size: 23px;
    opacity: 0.3;
  }
}

.total {
  padding: 20px;
  border-radius: 18px;

  &__commision {
    padding-bottom: 16px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;

    &:after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
      opacity: 0.1;
    }
  }

  &__sum {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 15px;
    }

    p {
      font-size: 23px;
    }
  }
}

.action {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 100%;
  border-radius: 18px;

  span {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }
}