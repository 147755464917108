.executedCommission {
  border-radius: 18px;
  padding: 20px;
  display: flex;
  justify-content: space-between;

  p {
    font-size: 15px;
    opacity: 0.4;
  }
}