.historyOrderItem {
  padding: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border-radius: 18px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__headerCurrency {
    display: flex;
    column-gap: 14px;
    align-items: center;

    p {
      font-size: 18px;
    }

    span {
      font-size: 12px;
    }
  }

  &__headerData {
    font-size: 12px;
    display: flex;
    column-gap: 4px;
  }
}

span.accept, span.cancel {
  font-size: 14px;
  padding-left: 7px;
  position: relative;
  color: #537C50;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-90%);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #537C50;
  }
}

span.cancel {
  color: #BA5349;

  &:before {
    background-color: #BA5349;
  }
}

