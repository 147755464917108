.filterCouple {
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
  max-width: 100%;

  &__favorite, &__favoriteActive, &__favoriteLight {
    width: 40px;
    height: 100%;
    border-radius: 50%;
    background-color: #0D182E;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all .2s;

    button {
      width: 40px;
      height: 40px;
      border-radius: 50%;

      img {
        pointer-events: none;
      }
    }
  }

  &__favoriteLight {
    background-color: #fff;
  }
}

.couplesWrapper {
  display: inline-flex;
  column-gap: 4px;
  overflow-x: auto;
  width: 100%;
  padding-right: 15px;
}

.couples {
  width: 60px;
  min-width: 60px;
  border-radius: 30px;
  position: relative;
  transition: all .2s;
  display: flex;

  input {
    width: 60px;
    height: 40px;
    opacity: 0;
    padding: 0;
    border-radius: 30px;
  }

  p {
    width: 60px;
    height: 100%;
    border-radius: 30px;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    min-height: 40px;

    span {
      padding-top: 2px;
      pointer-events: none;
    }
  }
}

.favorite {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  min-width: 40px;

  input {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  p {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
  }
}

.couples:has(input:checked) p span {
  color: #fff;
}