.depositWallet {
  width: 100%;
  height: 100vh;
  max-width: 640px;
  padding: 23px 16px;

  &__subtitle {
    margin: 24px 0;
    font-size: 24px;
    opacity: 0.3;
    text-align: center;
  }
}

.list ul {
  height: calc(100vh - 220px);
}