.popupHeaderQR {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
  }
}