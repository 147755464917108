.walletNetworkFilter {
  display: flex;
  column-gap: 8px;
  margin-bottom: 8px;

  &__checkbox {
    height: 44px;
    border-radius: 18px;
    width: 100%;
    position: relative;

    input {
      height: 100%;
      width: 100%;
    }
  }

  &__label {
    pointer-events: none;
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;
    border-radius: 14px;
    padding-left: 16px;
  }
}

.text {
  font-size: 15px;
  padding-top: 4px;
}