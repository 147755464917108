.googleOffConfirm {
  width: 100%;
  height: 100vh;
  max-width: 640px;
  padding: 23px 16px 25px;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.action {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  height: 65px;
  width: 100%;

  span {
    font-size: 18px;
    font-weight: 600;
  }
}
