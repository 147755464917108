.searchFull {
  position: relative;
  margin-top: 20px;

  img {
    position: absolute;
    left: 14px;
    top: calc(50% - 4px);
    transform: translateY(-50%);
  }

  input{
    height: 40px;
    width: 100%;
    margin-bottom: 8px;
  }
}