.spotFilter {
  margin: 8px 0;

  &__filter {
    background: #fff;
    border-radius: 14px;

    button {
      width: 100%;
      padding: 15px 8px;
      display: flex;
      column-gap: 14px;
      align-items: center;
      max-height: 52px;
      min-height: 52px;

      img {
        background-color: transparent;
      }

      span {
        opacity: 0.7;
        font-size: 18px;
        font-weight: 700;
      }

      p {
        margin-left: auto;
        font-size: 20px;
        font-weight: 700;
      }

      .arrow {
        //margin-left: auto;
        width: 15px;
        height: 15px;
      }

      .currencyIcon {
        width: 27px;
        height: 26px;
      }
    }
  }
}