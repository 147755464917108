.verificationGoogleOff {
  width: 100%;
  height: 100vh;
  max-width: 640px;
  padding: 23px 16px 45px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.lock {
  margin: 60px auto 32px;
  display: flex;
  justify-content: center;
  width: 168px;
  height: 168px;
  background-color: transparent;
}

.text {
  border-radius: 18px;
  padding: 16px 20px;
  font-size: 15px;
  margin-bottom: 32px;
}

.actions {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: auto;

  button {
    height: 63px;
    border-radius: 18px;

    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
}