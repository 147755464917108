.tokenWalletsList {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100% - 270px);
  padding-bottom: 8px;

  li {
    overflow: hidden;
  }
}