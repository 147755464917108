.headerWrapper {
  display: flex;
  justify-content: space-between;
}

.account {
  display: flex;
  align-items: center;
  column-gap: 12px;

  a {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 16px;
    color: #fff;
  }
}

.buttons {
  display: flex;
  align-items: center;
  column-gap: 8px;

  button {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.visible {
  display: block;
}

.hide {
  display: none
}
