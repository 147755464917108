.amlResult, .amlResultActive {
  position: absolute;
  left: -500%;
  top: 0;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  height: 100vh;
  background-position: 50% 5%;
  background-size: 115% 110%;
  background-repeat: no-repeat;
  overflow: auto;
  transition: all .5s;
  z-index: 3;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 36px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 120px;
  }

  &__content {
    height: 100%;
    margin-top: 74px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 16px;
    overflow: auto;
  }
}

.amlResultActive {
  left: 50%;
}

.content {
  &__header {
    display: flex;
    flex-direction: column;
    padding: 18px;
    border-radius: 18px;
    align-items: center;
  }

  &__title {
    margin-bottom: 18px;
    font-size: 32px;
    letter-spacing: -0.03em;
  }

  &__address {
    display: flex;
    justify-content: center;
    width: 100%;
    opacity: 0.3;
    font-size: 15px;
    letter-spacing: -0.03em;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__result {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    letter-spacing: -0.03em;
  }

  &__info {
    margin-top: 8px;
    border-radius: 18px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 28px;
  }

  &__line {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.infoBox {
  &__title {
    margin-bottom: 18px;
    font-size: 18px;
    letter-spacing: -0.03em;
    display: block;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    p {
      display: flex;
      align-content: center;
      justify-content: space-between;

      span {
        font-size: 15px;
        letter-spacing: -0.03em;
      }
    }

    p span:first-child {
      opacity: 0.6;
    }
  }

  &__result {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  &__transactions {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.03em;
  }

  &__date {
    opacity: 0.3;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.03em;
  }
}

.action {
  max-width: 640px;
  margin-top: 14px;

  button {
    height: 65px;
    width: 100%;
    border-radius: 18px;

    span {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.03em;
    }
  }
}