.wrapper {
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}

.eventPopup {
  width: 100%;
  height: 277px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;

  img {
    width: 59px;
    height: 59px;
    margin: 0 auto 24px;
  }

  &__text {
    font-size: 20px;
    text-align: center;
    margin-bottom: 24px;
  }

  &__textError {
    color: #BA5349;
    font-size: 20px;
    text-align: center;
    margin-bottom: 24px !important;
  }

  &__action {
    margin-top: auto;
    border-radius: 18px;
    height: 65px;

    span {
      font-size: 18px;
      font-weight: 600;
      color: #fff;
    }
  }
}