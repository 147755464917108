.contentValue {
  display: flex;
  column-gap: 28px;

  div {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
}

.orderItem {
  &__label {
    font-size: 12px;
    opacity: 0.5;
  }

  &__value {
    font-size: 12px;
  }
}

span.limitBuy, p.limitBuy {
  color: #537C50;
  font-size: 12px;
}

span.limitSell, p.limitSell {
  color: #BA5349;
  font-size: 12px;
}

p.orderItem__labelLimit {
  color: rgba(231, 208, 0, 0.77);
  font-size: 12px;
}