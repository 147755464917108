.priceWrapper {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.price {
  margin: 8px 0;
  padding: 6px 12px 5px;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  width: 57%;
  height: 48px;

  &__header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 4px;
  }

  &__value {
    display: flex;
    align-content: center;
    column-gap: 14px;

    input {
      width: 90%;
      padding-left: 0;
      font-size: 20px;
    }

    span {
      font-size: 16px;
      display: flex;
      align-items: center;
    }

    p {
      font-size: 20px;
    }
  }

  &__toggler {
    width: 43%;
    display: flex;
    column-gap: 4px;
  }

  &__buy, &__sell {
    height: 48px;
    width: 50%;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    p {
      position: absolute !important;
      border-radius: 14px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      pointer-events: none;
    }
  }
}

.price__buy:has(input:checked) p {
  background-color: #537C50;
  color: #fff;
}

.price__sell:has(input:checked) p {
  background-color: #BA5349;
  color: #fff;
}