.withdrawSum {
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  margin-top: 16px;
  row-gap: 16px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      opacity: 0.4;
      font-size: 15px;
      font-weight: 500;
    }
  }
}