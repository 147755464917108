.sendCode {
  padding: 16px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  label {
    font-size: 15px;
  }

  input {
    padding-left: 0;
    font-size: 20px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
}