.historyPeriod {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  margin-bottom: 16px;

  &__label {
    opacity: 0.3;
    margin: 24px 0 16px;
    font-size: 20px;
  }

  &__button {
    height: 40px;
    width: 100%;
    position: relative;

    input {
      height: 100%;
      width: 100%;
      opacity: 0;
    }

    p {
      position: absolute !important;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      border-radius: 14px;
      transition: all .15s;
    }
  }
}

.dateFields {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  margin-bottom: 10px;
  max-height: 60px;
  height: 60px;

  input {
    width: 100%;
    //height: 73px;
    font-size: 20px;
  }
}

.historyPeriod__button:has(input:checked) p {
  color: #fff;
}