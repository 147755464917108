.balanceWrapper {
  margin-top: 31px;

  p {
    font-size: 18px;
    margin-bottom: 16px;
    color: #fff;
  }

  h2 {
    font-family: 'Bai Jamjuree';
    font-weight: 700;
    font-size: 42px;
    color: #fff;
  }
}

.balance {
  display: flex;
  column-gap: 21px;
  align-items: center;

  img {
    height: 38px;
    width: 38px;
  }
}

span.currency {
  font-family: 'Bai Jamjuree';
  font-size: 16px;
  font-weight: 700;
  padding: 6px 13px;
  display: block;
  border-radius: 40px;
  text-transform: uppercase;
  color: #000;
}