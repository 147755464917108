.withdrawWallet {
  height: 100vh;
  padding: 23px 16px;
  overflow: auto;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  width: 100%;
  border-radius: 18px;
  font-size: 18px;
  margin-bottom: 8px;
}

.info {
  padding: 16px 20px;
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  border-radius: 18px;
  margin-bottom: 8px;

  p {
    color: #fff;
    font-size: 15px;
  }
}

.mainBtn {
  bottom: 22px;
  position: static;
  width: 100%;
  margin-top: 40px;

  &:disabled {
    opacity: 0.3;
  }
}

.closer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  z-index: 1;
}

.visible {
  display: block;
}

.hide {
  display: none
}