.transactionListCVV {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: auto;
  height: calc(100% - 370px);
  margin-top: 15px;
  min-height: 300px;

  li {
    padding: 14px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
}