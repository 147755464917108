.networksList {
  margin-top: 8px;
  padding: 18px;
  border-radius: 18px;

  &__label {
    font-size: 18px;
    margin-bottom: 29px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    li {
      position: relative;
      height: 52px;
      border-radius: 18px;

      input {
        opacity: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.networkChange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: 18px;
  padding: 18px;

  span {
    font-size: 18px;
    pointer-events: none;
  }
}