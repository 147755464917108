.withdrawValue {
  padding: 16px;
  border-radius: 18px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  span {
    opacity: 0.5;
    font-size: 15px;
  }
}

.value, .valueLight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:before {
    position: absolute;
    content: '=';
    left: 0;
    bottom: 7px;
    color: #fff;
  }

  input {
    padding-left: 12px;
  }

  button {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 8px;
    font-weight: 500;

    span {
      opacity: 1;
      color: #fff;
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.valueLight:before {
  color: #2C4A7E;
}

.footerValue {
  font-size: 15px;
  font-weight: 500;
  opacity: 0.5;
  padding: 0 16px;
}