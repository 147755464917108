.managerChoose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
  margin: 20px 0;

  &__button {
    height: 40px;
    width: 100%;
    position: relative;

    input {
      height: 100%;
      width: 100%;
      opacity: 0;
    }

    p {
      position: absolute !important;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      border-radius: 14px;
      transition: all .15s;
    }
  }
}

.managerChoose__button:has(input:checked) p {
  background-color: #2C4A7E;
  color: #fff;
}