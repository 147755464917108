.executedCurrency {
  padding: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  border-radius: 18px;
  margin-bottom: 32px;

  &__value {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
  }

  &__icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    background-color: transparent;
  }
}

.icons {
  display: flex;
  column-gap: 24px;
  align-items: center;
  position: relative;
}

.decoration {
  position: relative;
  width: 100%;
}

.stroke {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 24px;
  height: 24px;
  border-radius: 7px;
}

.currency {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}