.tokenInfo {
  padding: 20px;
  border-radius: 18px;
  margin-bottom: 8px;

  &__header {
    padding-bottom: 16px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      position: absolute;
      content: '';
      display: block;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;
      opacity: 0.1;
    }
  }

  &__headerNetwork {
    display: flex;
    align-items: center;
    column-gap: 10px;

    span:first-child {
      font-size: 15px;
      padding-top: 3px;
    }
  }

  &__label {
    padding: 7px;
    border-radius: 7px;
    text-transform: uppercase;
    font-size: 13px;
  }

  &__sent {
    display: block;
    margin: 15px 0;
    font-size: 15px;
    opacity: 0.3;
  }

  &__sentAddress,  {
    color: #E7DE00 !important;
    margin-bottom: 12px;

    span {
      color: #E7DE00;
    }
  }

  &__arrow {
    display: flex;
    justify-content: center;

    div {
      width: 27px;
      height: 22px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}