.steps {
  padding: 18px;
  border-radius: 18px;
  margin: 36px 0 8px;

  p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 32px;
  }

  &__process {
    display: flex;
    column-gap: 16px;
    justify-content: space-between;
    align-items: center;
  }
}

span.step, span.stepActive {
  height: 39px;
  width: 37px;
  min-width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  border-radius: 14px;
}


