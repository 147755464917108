.orderItemsList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: auto;
  max-height: 325px;
}

.orderPopup, .orderPopupActive {
  position: absolute;
  left: 50%;
  bottom: -1000px;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  transition: all .2s;
  z-index: 15;
  padding: 16px 16px 0;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  max-height: calc(100vh - 215px);

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: auto;
    max-height: calc(100vh - 280px);
    padding-bottom: 8px;
  }
}

.orderPopupActive {
  bottom: 0;
}

.close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}