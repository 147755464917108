.wrapper {
  border-radius: 18px;
  position: relative;
  width: 100%;
  height: 103px;
  min-height: 103px;
  overflow: hidden;
}

.wallet, .walletDelete {
  z-index: 1;
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 14px 18px 9px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  transition: all .2s;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  &__name {
    display: flex;
    align-items: center;
    column-gap: 11px;

    span {
      font-size: 20px;
    }
  }

  &__deposit {
    display: flex;
    column-gap: 3px;
    align-items: center;
    padding: 5px;

    img {
      width: 17px;
      height: 17px;
      pointer-events: none;
    }

    span {
      font-size: 15px;
      opacity: 0.7;
      padding-top: 2px;
      pointer-events: none;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 16px;
      opacity: 0.5;
    }
  }

  &__actions {
    display: flex;
    column-gap: 8px;
    align-items: center;

    button {
      border-radius: 8px;
      width: 44px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.index {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 10px;
  height: 100%;

  span {
    font-size: 10px;
    opacity: 0.5;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.walletDelete {
  left: 102px;
}

span.network {
  padding: 6px;
  background-color: #16399D;
  color: #fff;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
}

.delete {
  background-color: #BA5349;
  display: flex;
  align-items: center;
  padding-left: 18px;
  border-radius: 18px;
  height: 100%;
  width: 100%;

  span {
    font-size: 24px;
    color: #fff;
  }
}