.amlStepSecond {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 272px);

  &__action {
    margin-top: auto;
    position: relative;

    button {
      width: 100%;
      height: 65px;
      border-radius: 18px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: -0.03em;

      &:disabled {
        opacity: .3;
      }
    }
  }
}

.addressBox {
  border-radius: 18px;
  padding: 16px;
  margin: 8px 0 24px;

  p {
    opacity: 0.5;
    font-size: 15px;
    margin-bottom: 16px;
  }

  input {
    border-radius: 0;
    padding-left: 0;
    font-size: 20px;
    width: 100%;
  }
}

.blockchain {
  &__label {
    font-size: 18px;
    margin-bottom: 8px;
  }
}

.select {
  border-radius: 18px;
  margin-bottom: 8px;
  position: relative;
  height: 60px;

  img {
    width: 24px;
    height: 24px;
  }
}

.selectMain {
  padding: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.selectItems {
  font-size: 18px;
  letter-spacing: -0.03em;
}

.selectContent, .selectContentActive {
  position: absolute !important;
  left: 0;
  top: 75%;
  height: 0;
  margin-top: 20px;
  width: 100%;
  z-index: 2;
  border-radius: 18px;
  transition: all .2s;
  pointer-events: none;
  opacity: 0;
  max-height: 200px;
  overflow: auto;
  padding: 18px;

  li {
    padding-bottom: 36px;

    &:last-child {
      border-bottom-right-radius: 18px;
      border-bottom-left-radius: 18px;
      padding-bottom: 0;
    }
  }
}

.selectContentActive {
  height: auto;
  overflow: auto;
  opacity: 1;
  pointer-events: all;
}

.closer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}