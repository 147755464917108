.transactionTypeCVV {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 16px;

  &__title {
    opacity: 0.3;
    font-size: 18px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    column-gap: 8px;
  }

  &__choose {
    height: 40px;
    width: 100%;
    position: relative;

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 14px;
    }
  }
}

.transactionTypeCVV__choose:has(input:checked) span {
  color: #fff;
}