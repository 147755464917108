.networkPopup, .networkPopupActive {
  position: fixed;
  left: 50%;
  bottom: -1000px;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  transition: all .2s;
  z-index: 3;
  padding: 16px 16px 56px;
  height: auto;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.networkPopupActive {
  bottom: 0;
}

.close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.title {
  font-weight: 700;
  font-size: 19px;
  text-align: center;
  margin-bottom: 24px;
}

.networks {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  li {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 18px;

    input {
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.networkChange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  border-radius: 18px;
  padding: 18px;

  span {
    font-size: 18px;
    pointer-events: none;
  }
}