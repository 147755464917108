.amlHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: 700;
  }
}

.empty {
  opacity: 0;
  pointer-events: none;
}