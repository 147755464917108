.processRange {
  width: 90%;
  margin: 24px auto 0;

  &__value {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 12px;
      opacity: 0.3;
      font-weight: 700;
    }
  }

  input {
    padding-left: 0;
    width: 100%;
    opacity: 0;
    height: 16px;
  }

  &__range {
    position: relative;
    height: 16px;
    margin-bottom: 10px;
  }
}

