.content {
  height: calc(100vh - 293px);
  margin-top: 18px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 25px 16px 102px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  overflow: auto;

  li {
    border-radius: 18px;
  }

  p {
    font-size: 18px;
  }

  &__item {
    border-radius: 18px;
    padding: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__itemCurrency {
    display: flex;
    column-gap: 16px;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      background-color: transparent;
    }
  }
}