.main {
  height: 100vh;
  position: relative;
  background-position: 50% 35%;
  background-size: cover;
  z-index: 2;

  &__headerContainer {
    padding: 42px 14px 0;
  }
}