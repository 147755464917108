.change {
  border-radius: 18px;
  padding: 16px;
  min-height: 110px;



  &__header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0.3;

    p {
      font-size: 15px;
    }
  }

  &__balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  &__footer {
    font-size: 12px;
    opacity: 0.3;
  }
}

.inputField {
  max-width: 60%;
  display: flex;
  align-items: center;

  span {
    display: block;
    margin-right: 16px;
    max-width: 100%;
  }

  input {
    padding-left: 0;
    font-size: 24px;
    opacity: 0.5;
    width: 100%;
  }
}

.customSelect {
  display: flex;
  align-items: center;
  column-gap: 9px;
  position: relative;

  p {
    margin-right: 9px;
  }

  img {
    width: 30px;
    height: 30px;
    background-color: transparent;
  }

  &__up {
    transform: rotateZ(180deg);
    transition: all .2s;
  }

  &__upActive {
    transform: rotateZ(0deg);
  }

  &__content, &__contentActive {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    width: 100%;
    height: 0;
    pointer-events: none;
    display: none;
    flex-direction: column;
    column-gap: 6px;
    border-radius: 18px;
    background-color: #111E39;
    transition: all .2s;

    li {
      padding: 8px;
      opacity: 0;
      pointer-events: none;
      display: flex;
      align-items: center;
      column-gap: 9px;
      transition: all .15s;
      cursor: pointer;
    }
  }

  &__contentActive {
    transition: all .2s;
    height: auto;
    pointer-events: all;
    z-index: 3;
    display: flex;
    overflow: auto;
    max-height: calc(100vh - 350px);

    li {
      opacity: 1;
      pointer-events: all;
      transition: all .15s;
    }
  }
}

.closer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.icon {
  width: 40px;
  height: 40px;
}