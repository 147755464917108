.exchangeChoose {
  display: flex;
  column-gap: 8px;
  margin: 20px 0 9px;

  &__button {
    height: 39px;
    width: 100%;
    position: relative;
  }
}

.link, .linkActive {
  width: 100%;
  height: 100%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  transition: all .15s;
}


a.linkActive.active {
  color: #ffffff;
}