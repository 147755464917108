.couplesList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-height: calc(100vh - 210px);
  overflow: auto;

  &__item {
    padding: 30px 16px;
    border-radius: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__info {
    display: flex;
    align-items: center;
    column-gap: 16px;

    p {
      padding-top: 4px;
      font-size: 18px;
      font-weight: 500;
    }
  }

  &__value {
    display: flex;
    align-items: center;
    column-gap: 4px;

    p {
      font-size: 18px;
      font-weight: 500;
    }
  }
}