.executedProcess {
  border-radius: 18px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 8px;

  div {
    display: flex;
    justify-content: space-between;
  }

  p {
    font-size: 15px;
  }
}

p.process {
  color: #E7D000;
}