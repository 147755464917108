.historyType {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  margin: 20px 0 16px;

  &__label {
    opacity: 0.3;
    margin: 27px 0 16px;
    font-size: 20px;
  }

  &__button {
    height: 40px;
    width: 100%;
    position: relative;
  }
}

.link, .linkActive {
  width: 100%;
  height: 100%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  transition: all .15s;
}

a.linkActive.active {
  color: #ffffff;
}