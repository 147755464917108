.menu {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  column-gap: 6px;

  button {
    width: 100%;
    display: flex;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    height: 48px;
    background: #FFFFFF33;
    border-radius: 18px;

    span {
      font-size: 15px;
      color: #fff;
    }
  }
}