.orderItem {
  padding: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  border-radius: 18px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__headerCurrency {
    display: flex;
    column-gap: 14px;
    align-items: center;

    p {
      font-size: 18px;
    }

    span {
      font-size: 12px;
    }
  }

  &__headerData {
    font-size: 12px;
    display: flex;
    column-gap: 4px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
  }
}

.delete {
  width: 44px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

span.limitBuy, p.limitBuy {
  color: #537C50;
  font-size: 12px;
}

span.limitSell, p.limitSell {
  color: #BA5349;
  font-size: 12px;
}

.popup, .popupActive {
  padding: 32px 16px;
  position: fixed;
  height: 220px;
  max-width: 640px;
  width: 100%;
  z-index: 7;
  left: 0;
  bottom: -1000px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  transition: all .2s;

  &__actions {
    display: flex;
    column-gap: 8px;
    margin-top: 40px;

    button {
      height: 65px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border-radius: 18px;

      span {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.03em;
      }
    }
  }
}

.popupActive {
  bottom: 0;

  p {
    margin-top: 30px;
    font-size: 20px;
    text-align: center;
  }
}

.btnDelete {
  background-color: #BA5349;
}

.btnCancel {
  background-color: #537C50;
}

.shadow {
  position: fixed;
  z-index: 5;
  height: 100vh;
  top: 0;
  left: 0;
  width: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}