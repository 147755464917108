.walletsManager {
  height: 100vh;
  padding: 23px 16px 102px;
  display: flex;
  flex-direction: column;

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin: 20px 0 24px;
    overflow: auto;
    max-height: calc(100vh - 190px);
  }
}

.wallet {
  padding: 18px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &__header {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }

  &__headerName {
    font-size: 20px;
  }

  &__headerNetwork {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    display: flex;
    column-gap: 4px;
    padding: 6px 5px;
    border-radius: 7px;
  }

  &__index {
    opacity: 0.5;
    font-size: 12px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__footerActions {
    display: flex;
    align-items: center;
    column-gap: 8px;

    button {
      background-color: #132240;
      width: 44px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }
  }
}

.action {
  margin-top: auto;
  border-radius: 18px;
  margin-bottom: 24px;

  button {
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 100%;

    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
}