.ordersCVVList {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  row-gap: 8px;
  overflow: auto;
  height: calc(100% - 340px);

  li {
    padding: 14px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    column-gap: 16px;

    img {
      width: 40px;
      height: 40px;
    }
  }
}