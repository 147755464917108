.historyOrdersPopup, .historyOrdersPopupActive {
  position: fixed;
  left: 50%;
  bottom: -1000px;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  transition: all .2s;
  z-index: 4;
  padding: 16px 16px 50px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  height: calc(100vh - 84px);
}

.historyOrdersPopupActive {
  bottom: 0;
}

.close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

.ordersPopupContent {
  height: 100%;
  overflow: auto;
}