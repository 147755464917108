.priceChoose {
  display: flex;
  column-gap: 8px;

  &__item {
    width: 50%;
    height: 39px;
    position: relative;

    input {
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    p {
      position: absolute !important;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border-radius: 14px;
      pointer-events: none;
    }
  }
}

.priceChoose__item:has(input:checked) p {
  color: #fff;
}
