.scanAddAddress {
  display: flex;
  column-gap: 5px;
  margin-bottom: 8px;

  &__insert {
    padding: 0 16px 0 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 18px;

    span {
      font-size: 18px;
    }

    input {
      padding-left: 4px;
      font-size: 18px;
      height: 100%;
      width: 85%;
    }
  }

  &__scan {
    width: 58px;
    min-width: 58px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
  }
}