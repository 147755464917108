.depositNetwork {
  padding: 20px 18px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border-radius: 18px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    img {
      opacity: 0.5;
    }
  }

  &__headerName {
    display: flex;
    column-gap: 11px;
    align-items: center;
    overflow: hidden;
    max-width: 50%;

    p {
      font-size: 24px;
    }

    span {
      background-color: #16399d;
      border-radius: 7px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      padding: 6px;
    }
  }

  &__address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    p {
      font-size: 18px;
      opacity: 0.5;
      max-width: 70%;
      overflow: hidden;
    }

    button {
      width: 44px;
      height: 39px;
      border-radius: 8px;
    }
  }

  &__index {
    font-size: 18px;
    opacity: 0.5;
  }
}