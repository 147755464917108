.historyCVVFilter__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 16px 0 15px;
}

.historyCVVFilter {
  display: flex;
  justify-content: space-between;
  column-gap: 9px;

  &__title {
    opacity: 0.3;
    font-size: 20px;
  }

  &__search, &__choose {
    height: 40px;
    width: calc(100%/3);

    input {
      width: 100%;
      height: 100%;
    }
  }

  &__search {
    position: relative;

    img {
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__choose {
    position: relative;

    input {
      opacity: 0;
    }

    span {
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 14px;
    }
  }
}

.historyCVVFilter__choose:has(input:checked) span {
  color: #fff;
}