.exchangeContent {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: calc(100% - 128px);
  overflow: auto;
  padding-bottom: 28px;
}

.changeContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  position: relative;
}

.changeIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
}

.changeValue {
  display: flex;
  justify-content: center;
  font-size: 15px;

  span {
    padding: 0 4px;
  }
}

.btnExchange {
  margin-top: auto;
  height: 65px;
  min-height: 65px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  border-radius: 18px;
  color: #fff;
  //margin-bottom: 35px;

  &:disabled {
    opacity: 0.3;
  }
}

.loading {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 126px;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}