.walletInfo {
  border-radius: 18px;
  margin: 8px 0;
  padding: 18px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__name {
    display: flex;
    align-items: center;
    column-gap: 16px;
    font-size: 18px;

    img {
      width: 40px;
      height: 40px;
      background-color: transparent;
    }
  }

  &__address {
    display: flex;
    align-items: center;
    column-gap: 5px;

    span {
      font-size: 15px;
      opacity: 0.5;
      font-weight: 600;
    }
  }

  &__values {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
    }
  }
}

.event, .eventHide {
  border-radius: 11px;
  padding: 8px 11px;
  display: flex;
  column-gap: 10px;
  align-items: center;
  height: auto;
  transition: 0.15s;
  margin-top: 20px;

  img {
    height: 14px;
    width: 14px;
  }

  span {
    padding-top: 2px;
    color: #fff;
    height: auto;
  }
}

.eventHide {
  height: 0;
  padding: 0;
  transition: 0.15s;
  margin-top: 0;

  img {
    height: 0;
    display: none;
  }

  span {
    height: 0;
    display: none;
  }
}
