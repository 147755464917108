.verificationOptions {
  width: 100%;
  height: 100vh;
  max-width: 640px;
  padding: 23px 16px 45px;
  position: relative;

}

.options {
  margin-top: 23px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.option {
  padding: 16px;
  display: flex;
  border-radius: 18px;
  align-items: center;
  justify-content: space-between;

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    max-width: 80%;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
  }

  &__text {
    opacity: 0.5;
    font-size: 16px;
  }
}

.switcherBox, .switcherBoxActive {
  width: 51px;
  height: 31px;
  border-radius: 50px;
  background-color: #78788029;
  position: relative;
  transition: all .2s;
}

.switcherFalse, .switcherTrue {
  width: 27px;
  height: 27px;
  background-color: #fff;
  pointer-events: none;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0px 3px 1px 0px #0000000F;
  box-shadow: 0px 3px 8px 0px #00000026;
  left: 2px;
  top: 2px;
  transition: all .2s;
}

.switcherTrue {
  left: calc(100% - 29px);
}