.secondHeader {
  margin-top: 96px;
  display: flex;
  flex-direction: column;
  row-gap: 31px;
  align-items: center;

  button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #FFFFFF26;
  }

  p {
    font-weight: 700;
    font-size: 36px;
    color: #fff;
  }
}