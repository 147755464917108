.verificationKyc {
  width: 100%;
  height: 100vh;
  max-width: 640px;
  padding: 23px 16px 23px;
  overflow: auto;
  background-repeat: no-repeat;
  background-position: 50% 10%;
  background-size: 356px 356px;
  opacity: revert;

  &__content {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 375px;
    width: 100%;
    margin-top: 45px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 38px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 26px;
  }

  &__text {
    opacity: 0.6;
    font-size: 16px;
    line-height: 19.2px;
    text-align: center;
    max-width: 267px;
  }

  &__link {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 100%;
    border-radius: 18px;
    margin-bottom: 25px;

    span {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
    }
  }

  &__skip {
    font-size: 18px;
    font-weight: 600;
  }
}