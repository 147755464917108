.addWalletBtn {
  border-radius: 18px;
  width: 100%;
  height: 242px;
  position: relative;

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 42px;

    img {
      width: 47px;
      height: 47px;
    }

    span {
      font-size: 18px;
      color: #fff;
    }
  }
}