.historyOrdersList {
  margin-top: 37px;

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: auto;
    max-height: calc(100vh - 330px);
    padding-bottom: 8px;
  }
}