.amlStep {
  padding: 18px;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 33px;
  position: relative;

  p {
    text-align: center;
    font-size: 18px;
    margin-bottom: 32px;
  }

  &__process {
    display: flex;
    column-gap: 16px;
    justify-content: space-between;
    align-items: center;
    width: 166px;
  }
}

span.step, span.stepActive {
  height: 39px;
  width: 37px;
  min-width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 15px;
  border-radius: 14px;
}
