.process {
  display: block;
  margin: 24px auto 14px;
}

.balance {
  margin: 10px 0 24px;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
}

.closer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vh;
  height: 100vh;
  z-index: 1;
}

.orderListLabel {
  display: flex;
  justify-content: flex-start;
  font-size: 23px;
  margin: 9px 0 16px;
  opacity: 0.5;
}

.notOrders {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;
  opacity: 0.5;
  align-items: center;
  margin-top: 40px;

  img {
    width: 24px;
    height: 24px;
  }

  p {
    font-size: 23px;
  }
}

.error {
  color: #BA5349 !important;
  text-decoration: underline;
}