.account, {
  width: 100%;
  height: 100vh;
  max-width: 640px;
  padding: 23px 16px 23px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-size: 24px;
      font-weight: 700;
    }

    span {
      pointer-events: none;
      opacity: 0;
    }
  }

  &__info {
    margin-top: 20px;
    padding: 20px 25px;
    background-color: #0C1527;
    display: flex;
    align-items: center;
    column-gap: 9px;
    border-radius: 18px;

    p {
      font-size: 16px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    background-color: #FFFFFF26;

    img {
      width: 17px;
      height: 18px;
    }

    p {
      font-size: 24px;
    }
  }
}