.exchangeExecuted, .exchangeExecutedActive {
  position: absolute;
  left: -150%;
  top: 0;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  height: calc(100vh - 102px);
  background-position: 50% 5%;
  background-size: 115% 110%;
  background-repeat: no-repeat;
  overflow: auto;
  transition: all .1s;
  z-index: 2;

  &__content {
    height: calc(100vh - 389px);
    margin-top: 80px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 16px;
  }
}

.exchangeExecutedActive {
  left: 50%;
}