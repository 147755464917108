.verify, .verifyActive {
  position: absolute;
  left: -500%;
  top: 0;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  height: 100vh;
  background-position: 50% 5%;
  background-size: 115% 110%;
  background-repeat: no-repeat;
  overflow: auto;
  transition: all .5s;
  z-index: 3;
  display: flex;
  flex-direction: column;

  &__content {
    height: 100%;
    margin-top: 90px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 16px;
    overflow: auto;
  }
}

.verifyActive {
  left: 50%;
}

.token {
  padding: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  border-radius: 18px;

  &__name {
    display: flex;
    column-gap: 16px;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
    }

    span {
      font-size: 18px;
    }
  }

  &__value {
    font-size: 40px;
  }
}

.codeText {
  font-size: 15px;
  margin: 32px 0;
}

.code {
  height: 64px;
  width: 100%;
  font-size: 24px;
  padding-left: 16px;
}

.support {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin: 32px 0 40px;

  span {
    padding-top: 2px;
  }
}

.action {
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;

  &:disabled {
    opacity: 0.3;
  }

  span {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
}