.walletOperations {
  display: flex;
  justify-content: space-between;
  column-gap: 4px;
  margin: 20px 0 16px;

  li {
    border-radius: 14px;
    font-size: 15px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}