.walletItemOperations {
  display: flex;
  justify-content: space-between;
  column-gap: 4px;
  margin: 8px 0;

  li {
    border-radius: 14px;
    font-size: 15px;
    width: 100%;
    height: 40px;
    display: flex;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
  }
}