.withdrawNetworkPopup, .withdrawNetworkPopupActive {
  position: fixed;
  left: 50%;
  bottom: -1000px;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  transition: all .2s;
  z-index: 3;
  padding: 16px 16px 56px;
  height: calc(100vh - 85px);
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.header {
  border-radius: 18px;
  padding: 18px;
  display: flex;
  align-items: center;
  column-gap: 16px;

  p {
    font-size: 18px;
  }

  img {
    width: 40px;
    height: 40px;
  }
}

.withdrawNetworkPopupActive {
  bottom: 0;
}