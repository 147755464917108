.createWalletPopup, .createWalletPopupActive {
  position: fixed;
  left: 50%;
  bottom: -1000px;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  transition: all .2s;
  z-index: 3;
  padding: 16px 16px 32px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 24px auto 32px;
    text-align: center;
  }
}

.createWalletPopupActive {
  bottom: 0;
}

.name {
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 14px 16px;
  margin-bottom: 8px;

  label {
    opacity: 0.5;
    font-size: 15px;
    padding-left: 2px;
  }

  input {
    padding-left: 2px;
  }
}

.address {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-radius: 18px;
  margin-bottom: 8px;

  button {
    width: 51px;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  &__name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px;

    span {
      opacity: 0.5;
      font-size: 15px;
    }

    p {
      font-size: 16px;
      font-weight: 600;

    }
  }
}

.action {
  position: static;
  margin-top: 24px;
  width: 100%;

  &:disabled {
    opacity: 0.3;
  }
}

.preloader {
  height: 100% !important;
}